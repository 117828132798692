const form = document.querySelector('.online-form__field');

if (localStorage.getItem('name') === null) {
  form.elements.name.value = '';
} else {
  form.elements.name.value = localStorage.getItem('name');
}

if (localStorage.getItem('tel') === null) {
  form.elements.tel.value = '';
} else {
  form.elements.tel.value = localStorage.getItem('tel');
}

if (localStorage.getItem('message') === null) {
  form.elements.message.value = '';
} else {
  form.elements.message.value = localStorage.getItem('message');
}
function inputName(event) {
    if (event.target.name === 'name') {
      localStorage.setItem('name', event.target.value);
  }
  if (event.target.name === 'tel') {
    localStorage.setItem('tel', event.target.value);
  }
    if (event.target.name === 'message') {
      localStorage.setItem('message', event.target.value);
    }
}
function clearLocalStorage() {
   localStorage.removeItem('name');
   localStorage.removeItem('tel');
   localStorage.removeItem('message');

     form.elements.name.value = '';
     form.elements.tel.value = '';
     form.elements.message.value = '';
 }
form.addEventListener('input', () => inputName(event));
form.addEventListener('submit', clearLocalStorage);