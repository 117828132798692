(() => {
  const refs = {
    menuBtnBurger: document.querySelector('.menu__burger'),
    menuBtnCross: document.querySelector('.menu__cross'),
    mobileMenu: document.querySelector('[data-menu]'),
    backdropMenu: document.querySelector('[data-backdrop]'),
    body: document.body,
  }
  function openCloseMenu(event) {
    const expanded =
      refs.mobileMenu.getAttribute('aria-expanded') === 'true' || false;
    
    refs.body.classList.toggle('stop-scroll');
    refs.backdropMenu.classList.toggle('menu__backdrop');
    refs.menuBtnBurger.classList.toggle('menu--is-open');
    refs.menuBtnCross.classList.toggle('menu--is-open');
    refs.mobileMenu.classList.toggle('menu--is-open');

    refs.mobileMenu.setAttribute('aria-expanded', !expanded);
  }
  
  function closeMenu(event) {
    if (
      refs.mobileMenu.classList.contains('menu--is-open') &&
      event.target.id !== refs.mobileMenu.getAttribute('id') &&
      !event.target.closest('.menu__btn')
    ) {
      openCloseMenu(event);
    }
  }

  document.addEventListener('click', () => closeMenu(event));
  refs.menuBtnBurger.addEventListener('click', () => openCloseMenu(event));
  refs.menuBtnCross.addEventListener('click', () => openCloseMenu(event));
 })();
